import React, { useEffect, useState } from "react";
import { Badge, Button, Modal, Pagination, Alert } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const Invoices = ( {setBasicModal, depositButtonText, showDepositButton} ) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabledDeleteButton, setDisabledDeleteButton] = useState(false);
  const [selectedTransactionID, setSelectedTransactionID] = useState(0);
  
  const [paymentHistory, setPaymentHistory] = useState([]);
  
  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);

  useEffect(() => {
    getPaymentHistory(1);
  }, []);

  function setNavigationLinks(max_page) {
    var page_items_tmp = [];
    for (let number = active; number <= max_page; number++) {
      page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePage(page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    var max_page = maxPage;
    if(page_number > 5) {
      number_first = page_number - 5;
      max_page = max_page + number_first;
      if(max_page > maxAvailablePage){
        max_page = maxAvailablePage;
      }
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
    getPaymentHistory(page_number);
  }

  function changePageNoReload(page, max_page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    if(page_number > 5) {
        number_first = page_number - 5;
        max_page = max_page + number_first;
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePagePrev() {
    var next_page = active - 1;
    if(next_page < 1) {
      next_page = 1;
    }
    setActive(next_page);
    changePage(next_page);
  }

  function changePageNext() {
    const next_page = active + 1;
    if(next_page <= maxAvailablePage) {
      setActive(next_page);
      changePage(next_page);
    }
  }

  function convertTimestampToYmd(timestamp) {
    // // Create a new Date object from the timestamp
    // const date = new Date(timestamp * 1000);

    // // Extract the date components
    // const year = date.getFullYear();
    // const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // const day = ('0' + date.getDate()).slice(-2);
    // const hours = ('0' + date.getHours()).slice(-2);
    // const minutes = ('0' + date.getMinutes()).slice(-2);
    // const seconds = ('0' + date.getSeconds()).slice(-2);

    // // Construct the formatted date string
    // const formattedDate = year + '.' + month + '.' + day;

    // Convert timestamp to Date object
    let date = new Date(timestamp * 1000);

    // Define options for formatting the date
    let options = { 
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC' 
    };

    // Format the date
    let formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  async function getPaymentHistory(page_number) {
    const data = {
      token: Cookies.get('token'),
      page: page_number
    }

    try {
      const response = await axios.post(baseURL+'/api/invoices/get_payment_history', data);
      if(response.data.status === 'ok') {
        setPaymentHistory(response.data.payment_history);
        setNavigationLinks(response.data.max_page);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        changePageNoReload(page_number, response.data.max_page);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setLoadingBadge(false);
  }

  async function deletePayment() {
    setDisabledDeleteButton(true);
    const data = {
      token: Cookies.get('token'),
      payment_id: selectedTransactionID
    }

    try {
      const response = await axios.post(baseURL+'/api/invoices/delete_payment', data);
      if(response.data.status === 'ok') {
        setDeleteModal(false);
        getPaymentHistory(active);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setDisabledDeleteButton(false);
  }

  return (
    <div className="col-12">
      <div className="card overflow-hidden">
        <div className="card-header d-block d-sm-flex border-0">
          <div>
            <h4 className="fs-20 text-black">Transaction history</h4>
            <p className="mb-0 fs-12">
              
            </p>
          </div>
          {showDepositButton === true && (
            <div className="card-action card-tabs mt-3 mt-sm-0">
              <button className="btn btn-sm btn-success text-white" onClick={() => setBasicModal(true)}>{depositButtonText}</button>
            </div>
          )}
        </div>
        <div className="card-body pt-0">

          {loadingBadge === false && paymentHistory.length === 0 && (
            <Alert variant="primary" className="alert-dismissible fade show">
              There is no transaction history data.
            </Alert>
          )}

          {loadingBadge === false && paymentHistory.length > 0 && (
            <Table responsive className="w-100">
              <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="display w-100 dataTable"
                    role="grid"
                    aria-describedby="example_info"
                  >
                    <thead>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Payment method</th>
                        <th>Payment status</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                    
                      {paymentHistory &&
                        paymentHistory.map((item, key) => (
                          <tr key={key}>
                            <td role="row" className="wspace-no">{convertTimestampToYmd(item.timestamp)}</td>
                            <td className="wspace-no">{item.amount} USD</td>
                            <td>
                              {item.payment_type === 'topup' && (
                                <span>Top Up Balance</span>
                              )}
                              {item.payment_type === 'commission' && (
                                <span>Commission Charge</span>
                              )}
                            </td>
                            <td>
                              {item.payment_method === 'card' && (
                                <Badge variant="info light">Card</Badge>
                              )}
                              {item.payment_method === 'crypto' && (
                                <Badge variant="info light">Crypto</Badge>
                              )}
                            </td>
                            <td>
                              {item.status === 'success' && (
                                <Badge variant="success light">Success</Badge>
                              )}
                              {item.status === 'pending' && (
                                <Badge variant="primary light">
                                  <a href={item.stipe_url !== null ? item.stripe_url : "#"} target="_blank" rel="noreferrer">Pending: Click to open</a>
                                </Badge>
                              )}
                              {item.status === 'failed' && (
                                <Badge variant="danger light">Failed</Badge>
                              )}
                            </td>
                            <td>
                              {item.status !== 'success' && (
                                <button className="btn btn-xs btn-danger" onClick={() => {setSelectedTransactionID(item.id); setDeleteModal(true)} }>Delete</button>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                        
                    </tbody>
                    {/* <tfoot>
                        <tr role="row">
                          {data.jobsTable.columns.map((d, i) => (
                              <th key={i}>{d}</th>
                          ))}
                        </tr>
                    </tfoot> */}
                  </table>
              </div>
            </Table>
          )}

          {loadingBadge === false && paymentHistory.length > 0 && (
            <div className="">
              <Pagination
                size="sm"
                className={`mt-4 pagination-gutter pagination-primary no-bg pagination-circle`}
              >
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePagePrev()}>
                    <i className="la la-angle-left" />
                  </Link>
                </li>

                {pageItems.length > 0 &&
                  pageItems.map((item, i) => (
                    <Pagination.Item key={i} active={item.p === active ? true : false} onClick={() => {changePage(item.p)}}>
                      {item.p}
                    </Pagination.Item>
                  )
                )}
                
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePageNext()}>
                    <i className="la la-angle-right" />
                  </Link>
                </li>
              </Pagination>

              {loadingBadge === true && (
                <div className="mt-3">
                  <Badge variant="info light">Loading</Badge>
                </div>
              )}
              
            </div>
          )}

          <Modal className="fade" show={deleteModal}>
            <Modal.Header>
              <Modal.Title>Delete Transaction</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => setDeleteModal(false)}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>

              <h3 className="text-center">Are you sure that you want to delete this transaction?</h3>
              
              <div className="form-group row">
                <button className="btn btn-md btn-danger btn-block mt-2" onClick={() => deletePayment()} disabled={disabledDeleteButton}>
                  {disabledDeleteButton === false && (
                    <span>Delete</span>
                  )}
                  {disabledDeleteButton === true && (
                    <span>...</span>
                  )}
                </button>
              </div>

            </Modal.Body>
          </Modal>

        </div>
      </div>
    </div>
  );
};

export default Invoices;
