import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { Alert, Button, Modal } from "react-bootstrap";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const ExchangesEdit = ({ history }) => {
  const [coin, setCoin] = useState('Ethereum');
  const [walletAddress, setWalletAddress] = useState('');
  const [network, setNetwork] = useState('ETH (Ethereum, ERC20)');
  const [description, setDescription] = useState('');
  
  

  const [basicModal, setBasicModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [licenseType, setLicenseType] = useState('lifetime');
  const [freeTrialEnd, setFreeTrialEnd] = useState('');
  const [lifetimeLicenseActivated, setLifetimeLicenseActivated] = useState(0);
  

  const [disabledButton, setDisabledButton] = useState(false);
  const [disabledPasswordButton, setDisabledPasswordButton] = useState(false);
  const [disabledLicenseButton, setDisabledLicenseButton] = useState(false);
  
  const [emailChangeStatus, setEmailChangeStatus] = useState(false);
  const [passwordChangeStatus, setPasswordChangeStatus] = useState(false);
  const [licenseChangeStatus, setLicenseChangeStatus] = useState(false);
  

  const [disabledDeleteButton, setDisabledDeleteButton] = useState(false);
  const [disabledAPIFields, setDisabledAPIFields] = useState(true);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [error, setError] = useState('');


  // let { exchange_id } = useParams();

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getUserData();

    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  async function getUserData() {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id')

    setDisabledButton(true);
    const data = {
      token: Cookies.get('token'),
      user_id: user_id
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/users/get_one_user', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }

  async function changeEmail() {
    setEmailChangeStatus(false);

    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id');

    setDisabledButton(true);
    const data = {
      token: Cookies.get('token'),
      user_id: user_id,
      email: email
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/users/change_email', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        setEmailChangeStatus(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Payment Settings</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                {/* <form onSubmit={(e) => e.preventDefault()}> */}

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Coin</label>
                    <div className="col-sm-9">
                      <select className="form-control" value={coin} onChange={(e) => setCoin(e.target.value)}>
                        <option value={'Ethereum'} selected={coin === 'Ethereum' ? true : false}>Ethereum</option>
                        <option value={'USDT'} selected={coin === 'USDT' ? true : false}>USDT</option>
                        <option value={'DAI'} selected={coin === 'DAI' ? true : false}>DAI</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Wallet Address</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Network</label>
                    <div className="col-sm-9">
                      <select className="form-control" value={network} onChange={(e) => setNetwork(e.target.value)}>
                        <option value={'ETH (Ethereum, ERC20)'} selected={network === 'ETH (Ethereum, ERC20)' ? true : false}>ETH (Ethereum, ERC20)</option>
                        <option value={'MATIC (Polygon)'} selected={network === 'MATIC (Polygon)' ? true : false}></option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Description</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Enable</label>
                    <div className="col-sm-9">
                      <select className="form-control" onChange={(e) => setLicenseType(e.target.value)}>
                        <option value={'lifetime'} selected={licenseType === 'lifetime' ? true : false}>Lifetime License</option>
                        <option value={'percent'} selected={licenseType === 'percent' ? true : false}>% of profit</option>
                      </select>
                    </div>
                  </div>

                  

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      <button className="btn btn-md btn-info mr-3" disabled={disabledButton} onClick={() => changeEmail()}>
                        Save
                      </button>
                    </div>
                  </div>

                  {emailChangeStatus === true && (
                    <Alert variant="success" className="alert-dismissible fade show">
                      The email has been change successfully.
                    </Alert>
                  )}

                {/* </form> */}
              </div>
            </div>
          </div>
        </div>

      </div>

      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Delete Exchange</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setBasicModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">Are you sure you want to delete this exchange?</p>
          <button className="btn btn-md btn-danger btn-block mt-3" onClick={() => console.log()} disabled={disabledDeleteButton}>
            {disabledDeleteButton === false && (
              <span>Delete</span>
            )}
            {disabledDeleteButton === true && (
              <span>...</span>
            )}
          </button>

          {/* <p className="text-center pt-2" style={{lineHeight: '1.1'}}>
            <small></small>
          </p> */}

        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            onClick={() => setBasicModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ExchangesEdit;
