import React, { useContext, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";

import { Bar } from "react-chartjs-2";

import pattern1 from "../../../images/pattern/pattern1.png";
import pattern2 from "../../../images/pattern/pattern2.png";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const Dashboard = () => {
  const [totalProfit, setTotalProfit] = useState(0);
  const [, setTotalVolume] = useState(0);
  const [totalInvested, setTotalInvested] = useState(0);
  const [netProfitPercent, setNetProfitPercent] = useState(0);
  const [activeChart, setActiveChart] = useState('week');

  const [allUsers, setAllUsers] = useState(0);
  const [freeTrialUsers, setFreeTrialUsers] = useState(0);
  const [lifetimeActiveUsers, setLifetimeActiveUsers] = useState(0);
  const [percentProfitActiveUsers, setPercentProfitActiveUsers] = useState(0);

  const [chartDataValues, setChartDataValues] = useState([]);
  const [chartDataPercentValues, setChartDataPercentValues] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getMainStatistic();
    getChartData();
    checkUserAuth();

    SetBackgroundTheme(changeBackground, Cookies);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line
  }, [activeChart]);

  let path = window.location.pathname;
  path = path.split("/");
  var location = path[path.length - 1];
  location = location.split("?")[0];
  
  async function checkUserAuth() {
    const token = Cookies.get('token');
    const data = { token: token }
    const response = await axios.post(baseURL+'/api/admin/user/auth_check', data)
    if(response.data.status === 'ok') {
        Cookies.set('token', response.data.token, { expires: 1, path: '/'});

        if(location !== 'page-confirm-email' && location !== 'page-confirm-email-checking') {
          if(response.data.email_verified_at === null) {
            window.location.href = '/page-confirm-email';
            return "";
          }
        }

        if(location === 'page-login' || location === 'page-register' || location === 'page-forgot-password') {
          window.location.href = '/';
        }
    } else {
        if(location !== 'page-login' && location !== 'page-register' && location !== 'page-forgot-password' && location !== 'page-confirm-email' && location !== 'page-confirm-email-checking' && location !== 'page-password-reset') {
          window.location.href = '/page-login';
        }
    }
  }

  async function getMainStatistic() {
    const data = {token: Cookies.get('token')}

    try {
      const response = await axios.post(baseURL+'/api/admin/users/get_statistic', data);
      if(response.data.status === 'ok') {
        setAllUsers(response.data.all_users);
        setFreeTrialUsers(response.data.free_trial_users);
        setLifetimeActiveUsers(response.data.lifetime_active_users);
        setPercentProfitActiveUsers(response.data.percent_profit_active_users);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
  }

  async function getChartData() {
    const data = {
      token: Cookies.get('token'),
      chart_type: activeChart
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/users/get_earning_chart', data);
      console.log("Chart: ", response.data);
      if(response.data.status === 'ok') {
        setChartDataValues(response.data.data_values);
        // setChartDataPercentValues(response.data.data_percent_values);
        setChartLabels(response.data.labels);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
  }

  const chartData = {
    defaultFontFamily: "poppins",
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    labels: chartLabels,
    datasets: [
      {
        yAxisID: 'first',
        label: "USDT",
        // data: [65, -59, 80, -81, 56, -55, 40, -55, 40],
        data: chartDataValues,
        // borderColor: "rgba(64, 24, 157, 0)",
        borderWidth: "0",
        backgroundColor: setBarColor,
      },
      {
        yAxisID: 'second',
        label: "%",
        // data: [3.75, -1, 2, -3, 4, -5, 6, -7, 7],
        data: chartDataPercentValues,
        // borderColor: "rgba(64, 24, 157, 0)",
        borderWidth: "0",
        backgroundColor: '#00a8ff',
      },
    ]
  };

  function setBarColor(value) {
    if (value.dataset.data[value.dataIndex] > 0) {
      return "#61C277";
    } else {
      return "#FF3E3E";
    }
  }

  const chartOptions = {
    legend: false,
    scales: {
      yAxes: [
        {
          id: 'first',
          ticks: {
            display: true,
            beginAtZero: true,
            callback: function(value, index, values) {
              // Add label before each value
              return '$ ' + value;
            },
            // suggestedMin: Math.min(...chartDataValues, ...chartDataPercentValues),
            // suggestedMax: Math.max(...chartDataValues, ...chartDataPercentValues)
            suggestedMin: getSuggestedMin(chartDataValues),
            suggestedMax: getSuggestedMax(chartDataValues),

          },
          gridLines: {
            display: false
          },
          position: 'left',
        },
        {
          id: 'second',
          ticks: {
            display: true,
            beginAtZero: true,
            callback: function(value, index, values) {
              // Add label before each value
              return value + '%';
            },
            // suggestedMin: Math.min(...chartDataValues, ...chartDataPercentValues),
            // suggestedMax: Math.max(...chartDataValues, ...chartDataPercentValues)
            // suggestedMin: Math.min(...chartDataPercentValues),
            // suggestedMax: Math.max(...chartDataPercentValues)
          },
          gridLines: {
            display: false
          },
          position: 'right',
        },
      ],
      xAxes: [
        {
          // Change here
          barPercentage: 0.5,
          ticks: {
            display: true,
            beginAtZero: true,
          },
          gridLines: {
            display: false
          },
        },
      ],
    },
  };

  function getSuggestedMin(data) {
    const min = Math.min(...data);
    const max = Math.max(...data);

    const min_abs = Math.abs(min);
    const max_abs = Math.abs(max);

    var result_min = 0;
    if(min_abs < max_abs) {
      result_min = min_abs;
    } else {
      result_min = max_abs;
    }

    return result_min;
  }

  function getSuggestedMax(data) {
    const min = Math.min(...data);
    const max = Math.max(...data);

    const min_abs = Math.abs(min);
    const max_abs = Math.abs(max);

    var result_max = 0;
    if(min_abs > max_abs) {
      result_max = min_abs;
    } else {
      result_max = max_abs;
    }

    return result_max;
  }

  return (
    <div className="row">
      {/* <SetBackgroundTheme /> */}

      <div className="col-12 p-0 mb-4">
        <div className="row">
          <div className="col-12 col-sm-6 mb-2">
            <div className="items">
              <div
                className="wallet-card bg-info"
                style={{ backgroundImage: `url(${pattern1})` }}
              >
                <div className="head">
                  <p className="fs-14 text-white mb-0 op6 font-w100">Total Users</p>
                  <span>
                    {allUsers}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 mb-2">
            <div className="items">
              <div
                className="wallet-card bg-warning"
                style={{ backgroundImage: `url(${pattern2})` }}
              >
                <div className="head">
                  <p className="fs-14 text-white mb-0 op6 font-w100">Free Trial Users</p>
                  <span>
                    {freeTrialUsers}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 mb-2">
            <div className="items">
              <div
                className="wallet-card bg-primary"
                style={{ backgroundImage: `url(${pattern1})` }}
              >
                <div className="head">
                  <p className="fs-14 text-white mb-0 op6 font-w100">Active Users (Lifetime license)</p>
                  <span>
                    {lifetimeActiveUsers}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 mb-2">
            <div className="items">
              <div
                className="wallet-card bg-primary"
                style={{ backgroundImage: `url(${pattern2})` }}
              >
                <div className="head">
                  <p className="fs-14 text-white mb-0 op6 font-w100">Active Users (% of profit)</p>
                  <span>
                    {percentProfitActiveUsers}
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    
      <div className="col-12">
        <div className="card overflow-hidden">
          <div className="card-header d-block d-sm-flex border-0">
            <div>
              <h4 className="fs-20 text-black">PnL, USDT</h4>
              <p className="mb-0 fs-12">
                The Breakdown of Profit
              </p>
            </div>
            <div className="card-action card-tabs mt-3 mt-sm-0">
              <Nav as="ul" className="nav nav-tabs" role="tablist">
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className={activeChart === "week" ? "nav-link c-pointer active" : "nav-link c-pointer"}
                    data-toggle="tab"
                    eventKey="Week"
                    role="tab"
                    onClick={() => setActiveChart("week")}
                  >
                    Week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className={activeChart === "month" ? "nav-link c-pointer active" : "nav-link c-pointer"}
                    data-toggle="tab"
                    eventKey="Month"
                    role="tab"
                    onClick={() => setActiveChart("month")}
                  >
                    Month
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="card-body">
            <Bar data={chartData} height={100} options={chartOptions} />
          </div>
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
