import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Badge, Popover, OverlayTrigger, Button, Pagination, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import axios from 'axios';
import { baseURL, dashboardURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

import loadingGif from "../../../images/loading.gif";

const Statistics = () => {
  const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

  const [licenseType, setLicenseType] = useState("all");
  const [usersList, setUsersList] = useState([]);

  const [loadingClosedPositions, setLoadingClosedPositions] = useState(true);
  const [disableImpersonateButton, setDisableImpersonateButton] = useState(false);
  

  

  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getUsers(1);
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  function setNavigationLinks(max_page) {
    var page_items_tmp = [];
    for (let number = active; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePage(page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    var max_page = maxPage;
    if(page_number > 5) {
      number_first = page_number - 5;
      max_page = max_page + number_first;
      if(max_page > maxAvailablePage){
        max_page = maxAvailablePage;
      }
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
    getUsers(page_number);
  }

  function changePageNoReload(page, max_page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    if(page_number > 5) {
        number_first = page_number - 5;
        max_page = max_page + number_first;
    }

    var page_items_tmp = [];
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePagePrev() {
    var next_page = active - 1;
    if(next_page < 1) {
      next_page = 1;
    }
    setActive(next_page);
    changePage(next_page);
  }

  function changePageNext() {
    const next_page = active + 1;
    if(next_page <= maxAvailablePage) {
      setActive(next_page);
      changePage(next_page);
    }
  }

  async function getUsers(page_number) {
    setLoadingBadge(true);
    const data = {
      token: Cookies.get('token'),
      page: page_number
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/users/get', data);
      console.log("getUsers");
      console.log(response.data);

      if(response.data.status === 'ok') {
        setUsersList(response.data.users);
        setNavigationLinks(response.data.max_page);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        changePageNoReload(page_number, response.data.max_page);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
    setLoadingBadge(false);
    setLoadingClosedPositions(false);
  }

  function convertTimestampToYmdHis(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp * 1000);

    // Extract the date components
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // Construct the formatted date string
    const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    return formattedDate;
  }

  function convertTimestampToYmd(timestamp) {
    // // Create a new Date object from the timestamp
    // const date = new Date(timestamp * 1000);

    // // Extract the date components
    // const year = date.getFullYear();
    // const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // const day = ('0' + date.getDate()).slice(-2);
    // const hours = ('0' + date.getHours()).slice(-2);
    // const minutes = ('0' + date.getMinutes()).slice(-2);
    // const seconds = ('0' + date.getSeconds()).slice(-2);

    // // Construct the formatted date string
    // const formattedDate = year + '.' + month + '.' + day;

    // Convert timestamp to Date object
    let date = new Date(timestamp * 1000);

    // Define options for formatting the date
    let options = { 
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC' 
    };

    // Format the date
    let formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  async function impersonate(user_id, email) {
    setDisableImpersonateButton(true);
    const data = {
      token: Cookies.get('token'),
      user_id: user_id,
      email: email
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/users/impersonate', data);
      console.log("impersonate");
      console.log(response.data);

      if(response.data.status === 'ok') {
        const url = dashboardURL+'/page-impersonate?email='+email+'&token='+response.data.token;
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
    setDisableImpersonateButton(false);
  }

  return (
    <>
      
      <div className="d-flex align-items-center flex-wrap mb-3">
        {/* <div className="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <div className="input-group-append">
            <a href="#" className="input-group-text">
              <i className="flaticon-381-search-2" />
            </a>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search here"
          />
        </div> */}
        {/* <a
          href="#"
          className="btn btn-primary btn-rounded mb-3 mr-3"
        >
          <i className="las la-download scale5 mr-2" />
          Get Report
        </a>
        <a
          href="#"
          className="btn btn-outline-primary btn-rounded mb-3 mr-3 button-style"
        >
          <i className="las la-calendar scale5 mr-2" />
          Filter Periode
        </a> */}

        <Dropdown className="dropdown bootstrap-select form-control style-1 default-select mb-3">
          <Dropdown.Toggle
            variant=""
            type="button"
            className="btn dropdown-toggle btn-light"
            data-toggle="dropdown"
            role="button"
            title="Latest"
            aria-expanded="false"
          >
            {licenseType === "all" && (
              <span>License type: All</span>
            )}
            {licenseType === "lifetime" && (
              <span>License type: Lifetime</span>
            )}
            {licenseType === "percent" && (
              <span>License type: Percent of profit</span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu"
            role="combobox"
            x-placement="bottom-start"
            style={{
              maxHeight: 174,
              overflow: "hidden",
              minHeight: 0,
              position: "absolute",
              willChange: "transform",
              top: 0,
              left: 0,
              transform: "translate3d(0px, 41px, 0px)",
            }}
          >
            <Dropdown.Item onClick={() => {setLicenseType("lifetime")}}>
              License type: Lifetime
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {setLicenseType("percent")}}>
              License type: Percent of profit
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14 ">

            <div id="example6_wrapper" className="dataTables_wrapper no-footer">

              {loadingClosedPositions === true && (
                <Badge variant="info light">Loading...</Badge>
              )}

              {loadingClosedPositions === false && usersList.length === 0 && (
                <Alert variant="primary" className="alert-dismissible fade show">
                  There are no closed positions at this moment.
                </Alert>
              )}
              {loadingClosedPositions === false && usersList.length > 0 && (
                <table
                  className="table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                  id="marketCapital"
                  role="grid"
                  aria-describedby="example6_info"
                >
                  <thead>
                    <tr role="row">
                      <th>ID</th>
                      <th>Registered at</th>
                      <th>Email</th>
                      <th>Email verified</th>
                      <th>Demo Account</th>
                      <th>Trading Account</th>
                      <th>License Type</th>
                      <th>Commission Rate</th>
                      <th>Action</th>
                      {/* <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example6"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Coin: activate to sort column ascending"
                        // style={{ width: "118.667px" }}
                      >
                        Pair
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example6"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Last Price: activate to sort column ascending"
                        // style={{ width: "76.6667px" }}
                      >
                        Invested, USDT
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example6"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Change (24h): activate to sort column ascending"
                        // style={{ width: 104 }}
                      >
                        Entry Price
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example6"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Volume (24h): activate to sort column ascending"
                        // style={{ width: "113.333px" }}
                      >
                        Exit Price
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example6"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Graph: activate to sort column ascending"
                        // style={{ width: "124.667px" }}
                      >
                        Profit
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example6"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Graph: activate to sort column ascending"
                        // style={{ width: "124.667px" }}
                      >
                        Profit, %
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example6"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Graph: activate to sort column ascending"
                        // style={{ width: "124.667px" }}
                      >
                        Fees
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example6"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Graph: activate to sort column ascending"
                        // style={{ width: "124.667px" }}
                      >
                        Net Profit
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example6"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Graph: activate to sort column ascending"
                        // style={{ width: "124.667px" }}
                      >
                        Net Profit, %
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    
                    {usersList &&
                      usersList.map((item, key) => (
                        <tr key={key} role="row" className="odd">
                          <td className="wspace-no sorting_1">
                            {item.id}
                          </td>
                          <td className="wspace-no">
                            {convertTimestampToYmd(item.created_at)}
                          </td>
                          <td className="wspace-no">
                            {item.email}
                          </td>
                          <td className="wspace-no">
                            {item.email_verified_at === null && (
                              <Badge variant="warning light">No</Badge>
                            )}
                            {item.email_verified_at !== null && (
                              <Badge variant="success light">Yes</Badge>
                            )}
                          </td>
                          <td className="wspace-no">
                            {item.demo_account === false && (
                              <Badge variant="warning light">No</Badge>
                            )}
                            {item.demo_account === true && (
                              <Badge variant="success light">Yes</Badge>
                            )}
                          </td>
                          <td className="wspace-no">
                            {item.trading_account === false && (
                              <Badge variant="warning light">No</Badge>
                            )}
                            {item.trading_account === true && (
                              <Badge variant="success light">Yes</Badge>
                            )}
                          </td>
                          <td className="wspace-no">
                            {item.license_type === 'lifetime' && (
                              <span>
                                Lifetime:{' '}
                                {item.lifetime_license === 0 && currentTimestampInSeconds < item.free_trial_end_timestamp &&(
                                  <Badge variant="warning light">Free trial until {convertTimestampToYmd(item.free_trial_end_timestamp)}</Badge>
                                )}
                                {item.lifetime_license === 0 && currentTimestampInSeconds >= item.free_trial_end_timestamp &&(
                                  <Badge variant="warning light">Not active</Badge>
                                )}
                                {item.lifetime_license === 1 && (
                                  <Badge variant="success light">Activated</Badge>
                                )}
                              </span>
                            )}
                            {item.license_type === 'percent' && (
                              <span>Percent</span>
                            )}
                          </td>
                          <td className="wspace-no">
                            {item.license_type === 'lifetime' && (
                              <span>0%</span>
                            )}
                            {item.license_type === 'percent' && (
                              <span>{item.commission}%</span>
                            )}
                          </td>
                          <td className="wspace-no">
                            <Link className="btn btn-xs btn-info" to={`/users/edit?id=${item.id}`}>Manage</Link>
                            <button className="btn btn-xs btn-warning ml-1" onClick={() => impersonate(item.id, item.email)} disabled={disableImpersonateButton}>
                              {disableImpersonateButton === false && (
                                <span>Impersonate</span>
                              )}
                              {disableImpersonateButton === true && (
                                <span><img alt="" src={loadingGif} width="18" /></span>
                              )}
                            </button>
                          </td>
                          {/* <td>{item.position_size_usdt === null ? "" : item.position_size_usdt.toFixed(4)}</td>
                          <td>{item.entry_avg_price}</td>
                          <td>{item.exit_price}</td>
                          <td>{item.profit === null ? "" :  item.profit.toFixed(4)}</td>
                          <td>
                            <p className="mb-0 wspace-no">
                              <i
                                className={item.profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                aria-hidden="true"
                              />
                              {item.profit_percent === null ? "" : item.profit_percent.toFixed(2)}%
                            </p>
                          </td>
                          <td>{item.total_commission_usdt === null ? "" : item.total_commission_usdt.toFixed(10)}</td>
                          <td>{item.net_profit === null ? "" : item.net_profit.toFixed(4)}</td>
                          <td>
                            <p className="mb-0 wspace-no">
                              <i
                                className={item.net_profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                aria-hidden="true"
                              />
                              {item.net_profit_percent === null ? "" : item.net_profit_percent.toFixed(2)}%
                            </p>
                          </td> */}
                        </tr>
                      )
                    )}

                  </tbody>
                </table>
              )}
            </div>

          </div>

          <div className="">
            <Pagination
              size="sm"
              className={`mt-4 pagination-gutter pagination-primary no-bg pagination-circle`}
            >
              <li className="page-item page-indicator">
                <Link className="page-link" to="#" onClick={() => changePagePrev()}>
                  <i className="la la-angle-left" />
                </Link>
              </li>

              {pageItems.length > 0 &&
                pageItems.map((item, i) => (
                  <Pagination.Item key={i} active={item.p === active ? true : false} onClick={() => {changePage(item.p)}}>
                    {item.p}
                  </Pagination.Item>
                )
              )}
              
              <li className="page-item page-indicator">
                <Link className="page-link" to="#" onClick={() => changePageNext()}>
                  <i className="la la-angle-right" />
                </Link>
              </li>
            </Pagination>

            {loadingBadge === true && (
              <div className="mt-3">
                <Badge variant="info light">Loading</Badge>
              </div>
            )}
            
          </div>

        </div>
      </div>
    </>
  );
};

export default Statistics;
