import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Badge, Popover, OverlayTrigger, Button, Pagination, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const Statistics = () => {
  const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

  const [licenseType, setLicenseType] = useState("all");
  const [paymentSettingsList, setPaymentSettingsList] = useState([]);

  const [loadingPaymentSettings, setLoadingPaymentSettings] = useState(true);
  

  

  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getPaymentSettings();
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  async function getPaymentSettings() {
    setLoadingBadge(true);
    const data = { token: Cookies.get('token') }

    try {
      const response = await axios.post(baseURL+'/api/admin/payment-settings/get', data);
      console.log("getUsers");
      console.log(response.data);

      if(response.data.status === 'ok') {
        setPaymentSettingsList(response.data.payment_methods);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
    setLoadingBadge(false);
    setLoadingPaymentSettings(false);
  }

  return (
    <>
      
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14 ">

            <div id="example6_wrapper" className="dataTables_wrapper no-footer">

              {loadingPaymentSettings === true && (
                <Badge variant="info light">Loading...</Badge>
              )}

              {loadingPaymentSettings === false && paymentSettingsList.length === 0 && (
                <Alert variant="primary" className="alert-dismissible fade show">
                  There are no payment settings at this moment.
                </Alert>
              )}
              {loadingPaymentSettings === false && paymentSettingsList.length > 0 && (
                <table
                  className="table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                  id="marketCapital"
                  role="grid"
                  aria-describedby="example6_info"
                >
                  <thead>
                    <tr role="row">
                      <th>ID</th>
                      <th>Coin</th>
                      <th>Wallet Address</th>
                      <th>Network</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {paymentSettingsList &&
                      paymentSettingsList.map((item, key) => (
                        <tr key={key} role="row" className="odd">
                          <td className="wspace-no sorting_1">
                            {item.id}
                          </td>
                          <td className="wspace-no">
                            {item.coin_name}
                          </td>
                          <td className="wspace-no">
                            {item.wallet_address}
                          </td>
                          <td className="wspace-no">
                            {item.network}
                          </td>
                          <td className="wspace-no">
                            {item.enable === 0 && (
                              <Badge variant="warning light">Disabled</Badge>
                            )}
                            {item.enable === 1 && (
                              <Badge variant="success light">Enable</Badge>
                            )}
                          </td>
                          <td className="wspace-no">
                            <Link className="btn btn-xs btn-info" to={`/payment-settings/edit?id=${item.id}`}>Manage</Link>
                          </td>
                        </tr>
                      )
                    )}

                  </tbody>
                </table>
              )}
            </div>

          </div>

        </div>
      </div>
    </>
  );
};

export default Statistics;
