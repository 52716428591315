import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Badge } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";

import pattern1 from "../../../images/pattern/pattern1.png";
import pattern2 from "../../../images/pattern/pattern2.png";

import axios from 'axios';
import { baseURL, stripePubKey, dashboardName } from "../../../baseURL";
import Cookies from 'js-cookie';
import {loadStripe} from '@stripe/stripe-js';

import TransactionHistory from './TransactionHistory.js'

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const License = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [licenseActive, setLicenseActive] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState('card');
  const [depositAmount, setDepositAmount] = useState(399);

  const [disabledDepositButton, setDisabledDepositButton] = useState(false);
  

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  

  async function createPayment() {
    setDisabledDepositButton(true);

    if(paymentMethod === 'card') {
      const data = {
        token: Cookies.get('token'),
        price: depositAmount,
        license_type: 'lifetime'
      }

      try {
        const response = await axios.post(baseURL+'/api/invoices/stripe/create_payment', data);
        if(response.data.status === 'ok') {
          const stripe = await loadStripe(stripePubKey);
          stripe.redirectToCheckout({
            sessionId: response.data.session_id
          })
        } else {
          // setError(response.data.text);
        }
      } catch(err) {

      }
    }

    if(paymentMethod === 'crypto') {
      const data = {
        token: Cookies.get('token'),
        price: depositAmount
      }

      try {
        const response = await axios.post(baseURL+'/api/invoices/coinbase/create_payment', data);
        if(response.data.status === 'ok') {
          window.location.href = response.data.hosted_url;
        } else {
          // setError(response.data.text);
        }
      } catch(err) {

      }
    }

    setDisabledDepositButton(false);
  }

  return (
    <div className="row">

      {loading === true && (
        <div className="col-12">
          <Badge variant="info light">Loading...</Badge>
        </div>
      )}

      {loading === false && (
        <>
          <div className="col-12 p-0 mb-4">
            <div className="row">
            <div className="col-12 col-sm-6 mb-2">
                <div className="items">
                  <div
                    className="wallet-card bg-primary"
                    style={{ backgroundImage: `url(${pattern2})` }}
                  >
                    <div className="head">
                      <p className="fs-14 text-white mb-0 op6 font-w100">Lifetime License status</p>
                      {/* <span>${unpaidCommission}</span> */}
                      {licenseActive === 1 && (
                        <button className="mt-2 btn btn-xs btn-success text-white">Activated</button>
                      )}
                      {licenseActive === 0 && (
                        <button className="mt-2 btn btn-xs btn-warning text-white">Not Active</button>
                      )}
                    </div>
                    {/* <div className="wallet-footer">
                      <span className="fs-14">444 221 224 ***</span>
                      <img src={cardLogo2} alt="" />
                    </div> */}
                  </div>
                </div>
              </div>
              {licenseActive === 0 && (
                <div className="col-12 col-sm-6 mb-2">
                  <div className="items">
                    <div
                      className="wallet-card bg-info"
                      style={{ backgroundImage: `url(${pattern1})` }}
                    >
                      <div className="head">
                        <p className="fs-14 text-white mb-0 op6 font-w100">Action</p>
                        <button className="mt-2 btn btn-xs btn-success text-white" onClick={() => setBasicModal(true)}>Activate Lifetime License</button>
                      </div>
                      {/* <div className="wallet-footer">
                        <span className="fs-14">444 221 224 ***</span>
                        <img src={cardLogo} alt="" />
                      </div> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <TransactionHistory setBasicModal={setBasicModal} depositButtonText="Activate Lifetime License" showDepositButton={licenseActive === 0 ? true : false} />
          
          <Modal className="fade" show={basicModal}>
            <Modal.Header>
              <Modal.Title>Activate Lifetime License</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => setBasicModal(false)}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>

              {/* <label className="col-sm-3 col-form-label">Deposit</label> */}

              <p className="text-center">Deposit {depositAmount} USD to active the lifetime license.</p>
              {/* <p className="text-center" style={{lineHeight: '1.1'}}><small>
                Minimum amount to deposit:<br/>
                * 25 USD for Bitcoin payments<br/>
                * 5 USD for payments with other coins
              </small></p> */}

              <div className="form-group row text-center">
                <select className="form-control text-center" onChange={(e) => setPaymentMethod(e.target.value)}>
                  <option value="card" selected={paymentMethod === 'card' ? true : false}>Credit/Debit Card</option>
                  {/* <option value="crypto" selected={paymentMethod === 'crypto' ? true : false}>Crypto</option> */}
                </select>
              </div>

              <div className="form-group row">
                <button className="btn btn-md btn-info btn-block mt-2" onClick={() => createPayment()} disabled={disabledDepositButton}>Next</button>
              </div>

              {/* <p className="text-center pt-2" style={{lineHeight: '1.1'}}>
                <small>We accept 5 coins: USDT, USDC, Bitcoin, Ethereum, Litecoin.</small>
              </p> */}

              <p className="text-center pt-0" style={{lineHeight: '1.1'}}>
                <small>
                Note: The {dashboardName} balance is not your trading balance.<br/>
                It is for paying invoices, not for trading!
                </small>
              </p>

            </Modal.Body>
            {/* <Modal.Footer>
              <Button
                onClick={() => setBasicModal(false)}
                variant="danger light"
              >
                Close
              </Button>
              <Button variant="primary">Save changes</Button>
            </Modal.Footer> */}
          </Modal>
        </>
      )}

    </div>
  );
};

export default License;
