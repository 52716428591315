import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { Alert, Button, Modal } from "react-bootstrap";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const ExchangesEdit = ({ history }) => {
  const [basicModal, setBasicModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [licenseType, setLicenseType] = useState('lifetime');
  const [freeTrialEnd, setFreeTrialEnd] = useState('');
  const [lifetimeLicenseActivated, setLifetimeLicenseActivated] = useState(0);
  

  const [disabledButton, setDisabledButton] = useState(false);
  const [disabledPasswordButton, setDisabledPasswordButton] = useState(false);
  const [disabledLicenseButton, setDisabledLicenseButton] = useState(false);
  
  const [emailChangeStatus, setEmailChangeStatus] = useState(false);
  const [passwordChangeStatus, setPasswordChangeStatus] = useState(false);
  const [licenseChangeStatus, setLicenseChangeStatus] = useState(false);
  

  const [disabledDeleteButton, setDisabledDeleteButton] = useState(false);
  const [disabledAPIFields, setDisabledAPIFields] = useState(true);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [error, setError] = useState('');


  // let { exchange_id } = useParams();

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getUserData();

    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  async function getUserData() {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id')

    setDisabledButton(true);
    const data = {
      token: Cookies.get('token'),
      user_id: user_id
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/users/get_one_user', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        // history.push("/exchanges");
        setEmail(response.data.user.email);
        setLicenseType(response.data.user.license_type);
        setFreeTrialEnd(convertTimestampToYmd(response.data.user.free_trial_end_timestamp));
        setLifetimeLicenseActivated(response.data.user.lifetime_license);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }

  async function changeEmail() {
    setEmailChangeStatus(false);

    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id');

    setDisabledButton(true);
    const data = {
      token: Cookies.get('token'),
      user_id: user_id,
      email: email
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/users/change_email', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        setEmailChangeStatus(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }

  async function changePassword() {
    setPasswordChangeStatus(false);

    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id');

    setDisabledPasswordButton(true);
    const data = {
      token: Cookies.get('token'),
      user_id: user_id,
      password: password
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/users/change_password', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        setPasswordChangeStatus(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledPasswordButton(false);
  }

  async function changeLicenseSettings() {
    setLicenseChangeStatus(false);

    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get('id');

    const free_trial_end_timestamp = convertToTimestamp(freeTrialEnd);

    setDisabledLicenseButton(true);
    const data = {
      token: Cookies.get('token'),
      user_id: user_id,
      license_type: licenseType,
      lifetime_license: lifetimeLicenseActivated,
      free_trial_end_timestamp: free_trial_end_timestamp,
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/users/change_license_settings', data);
      console.log(response.data);
      if(response.data.status === 'ok') {
        setLicenseChangeStatus(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledLicenseButton(false);
  }

  function convertToTimestamp(dateString) {
    const date = new Date(dateString);
    return parseInt(date.getTime() / 1000);
  }

  function convertTimestampToYmd(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp * 1000);

    // Extract the date components
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // Construct the formatted date string
    const formattedDate = year + '-' + month + '-' + day;
    return formattedDate;
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Email</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                {/* <form onSubmit={(e) => e.preventDefault()}> */}

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      <button className="btn btn-md btn-info mr-3" disabled={disabledButton} onClick={() => changeEmail()}>
                        Save
                      </button>
                    </div>
                  </div>

                  {emailChangeStatus === true && (
                    <Alert variant="success" className="alert-dismissible fade show">
                      The email has been change successfully.
                    </Alert>
                  )}

                {/* </form> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Change Password</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                {/* <form onSubmit={(e) => e.preventDefault()}> */}

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Password</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      <button className="btn btn-md btn-info mr-3" disabled={disabledPasswordButton} onClick={() => changePassword()}>
                        Save
                      </button>
                    </div>
                  </div>

                  {passwordChangeStatus === true && (
                    <Alert variant="success" className="alert-dismissible fade show">
                      The password has been change successfully.
                    </Alert>
                  )}

                {/* </form> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Payment Option</label>
                    <div className="col-sm-9">
                      <select className="form-control" onChange={(e) => setLicenseType(e.target.value)}>
                        <option value={'lifetime'} selected={licenseType === 'lifetime' ? true : false}>Lifetime License</option>
                        <option value={'percent'} selected={licenseType === 'percent' ? true : false}>% of profit</option>
                      </select>
                    </div>
                  </div>

                  {licenseType === 'lifetime' && (
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Lifetime License Activated</label>
                      <div className="col-sm-9">
                        <select className="form-control" onChange={(e) => setLifetimeLicenseActivated(e.target.value)}>
                          <option value={1} selected={lifetimeLicenseActivated === 1 ? true : false}>Yes</option>
                          <option value={0} selected={lifetimeLicenseActivated === 0 ? true : false}>No</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {licenseType === 'lifetime' && (
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Free Trial End</label>
                      <div className="col-sm-9">
                        <input
                          type="date"
                          className="form-control pt-3"
                          placeholder=""
                          value={freeTrialEnd}
                          onChange={(e) => setFreeTrialEnd(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      <button className="btn btn-md btn-info mr-3 mb-2" disabled={disabledLicenseButton} onClick={() => changeLicenseSettings()}>
                        Save
                      </button>
                    </div>
                  </div>

                  {licenseChangeStatus === true && (
                    <Alert variant="success" className="alert-dismissible fade show">
                      The license settings has been change successfully.
                    </Alert>
                  )}

                  {/* <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-md btn-info">
                        Connect
                      </button>
                    </div>
                  </div> */}

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Delete Exchange</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setBasicModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">Are you sure you want to delete this exchange?</p>
          <button className="btn btn-md btn-danger btn-block mt-3" onClick={() => console.log()} disabled={disabledDeleteButton}>
            {disabledDeleteButton === false && (
              <span>Delete</span>
            )}
            {disabledDeleteButton === true && (
              <span>...</span>
            )}
          </button>

          {/* <p className="text-center pt-2" style={{lineHeight: '1.1'}}>
            <small></small>
          </p> */}

        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            onClick={() => setBasicModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ExchangesEdit;
