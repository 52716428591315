import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { Alert, Button, Modal } from "react-bootstrap";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const ExchangesEdit = ({ history }) => {
  const [basicModal, setBasicModal] = useState(false);
  const [firstGet, setFirstGet] = useState(true);

  const [exchangePlatform, setExchangePlatform] = useState('binance');
  const [internalName, setInternalName] = useState('');
  const [apiKey, setAPIKey] = useState('');
  const [apiSecret, setAPISecret] = useState('');
  const [tradingCapital, setTradingCapital] = useState(100);
  const [positionSize, setPositionSize] = useState(10);
  const [positionSizeType, setPositionSizeType] = useState('usdt');
  const [reinvestProfit, setReinvestProfit] = useState(0);

  const [disabledButton, setDisabledButton] = useState(false);
  const [disabledDeleteButton, setDisabledDeleteButton] = useState(false);
  const [disabledAPIFields, setDisabledAPIFields] = useState(true);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [error, setError] = useState('');


  // let { exchange_id } = useParams();

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getExchange();

    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  useEffect(() => {
    if(firstGet === false) {
      const timeOutId = setTimeout(() => changePositionSize(positionSize), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [positionSize]);

  function changePositionSize(values, position_size_type) {
    // const values = parseFloat(e.target.value);
    if(values < 0) {
      setPositionSize(1);
      return "";
    }

    if(positionSizeType === 'usdt' || position_size_type === 'usdt') {
      if(values < 8) {
        setPositionSize(8);
        return "";
      }

      if(values > tradingCapital) {
        setPositionSize(tradingCapital);
        return "";
      }
    }

    if(positionSizeType === 'percent' || position_size_type === 'percent') {
      if(values > 100) {
        setPositionSize(100);
        return "";
      }

      if(values <= 0) {
        setPositionSize(1);
        return "";
      }
    }

    setPositionSize(values);
  }

  function changePositionSizeType(value) {
    setPositionSizeType(value);
    changePositionSize(positionSize, value);
  }

  async function getExchange() {
    const urlParams = new URLSearchParams(window.location.search);
    const exchange_id = urlParams.get('id')
    
    const data = {
      token: Cookies.get('token'),
      exchange_id: exchange_id
    }

    try {
      const response = await axios.post(baseURL+'/api/exchange/get_exchange_info', data);
      if(response.data.status === 'ok') {
        setExchangePlatform(response.data.exchange.platform);
        setInternalName(response.data.exchange.name);
        setTradingCapital(response.data.exchange.invested_max);
        setPositionSize(response.data.exchange.position_size);
        setPositionSizeType(response.data.exchange.position_size_type);
        setReinvestProfit(response.data.exchange.reinvest_profits);

        if(response.data.exchange.status === 'connected') {
          setDisabledAPIFields(true);
          setAPIKey('******');
          setAPISecret('******');
        } else {
          setDisabledAPIFields(false);
        }

        setFirstGet(false);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
  }

  
  async function checkExchange() {
    const urlParams = new URLSearchParams(window.location.search);
    const exchange_id = urlParams.get('id')

    setDisabledButton(true);
    const data = {
      token: Cookies.get('token'),
      exchange_id: exchange_id
    }

    try {
      const response = await axios.post(baseURL+'/api/exchange/check_connection', data);
      if(response.data.status === 'ok') {
        history.push("/exchanges");
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }

  async function editExchange() {

    if(positionSizeType === 'usdt' && positionSize < 8) {
      setError("The minimum position size is 8 USDT.");
      return "";
    }

    const urlParams = new URLSearchParams(window.location.search);
    const exchange_id = urlParams.get('id')

    setDisabledButton(true);
    const data = {
      token: Cookies.get('token'),
      exchange_id: exchange_id,
      name: internalName,
      platform: exchangePlatform,
      key: apiKey,
      secret: apiSecret,
      invested_max: tradingCapital,
      positions_number_max: 10000,
      positions_number_same_pair_max: 1,
      position_size: positionSize,
      position_size_type: positionSizeType,
      reinvest_profits: reinvestProfit
    }

    try {
      const response = await axios.post(baseURL+'/api/exchange/edit', data);
      if(response.data.status === 'ok') {
        setSuccessUpdate(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledButton(false);
  }

  async function deleteExchange() {
    const urlParams = new URLSearchParams(window.location.search);
    const exchange_id = urlParams.get('id')

    setDisabledDeleteButton(true);
    const data = {
      token: Cookies.get('token'),
      exchange_id: exchange_id
    }

    try {
      const response = await axios.post(baseURL+'/api/exchange/delete', data);
      if(response.data.status === 'ok') {
        history.push("/exchanges")
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledDeleteButton(false);
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Connect new exchange</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>

                <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Exchange</label>
                    <div className="col-sm-9">
                      <select className="form-control" disabled={true} onChange={(e) => setExchangePlatform(e.target.value)}>
                        <option value={'binance'} selected={exchangePlatform === 'binance' ? true : false} >Binance</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Internam name</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        value={internalName}
                        onChange={(e) => setInternalName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">API Key</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        value={apiKey}
                        onChange={(e) => setAPIKey(e.target.value)}
                        disabled={disabledAPIFields}
                      />
                    </div>
                  </div>
                  
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">API Secret</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=""
                        value={apiSecret}
                        onChange={(e) => setAPISecret(e.target.value)}
                        disabled={disabledAPIFields}
                      />
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Trading Capital, USDT</label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        placeholder=""
                        value={tradingCapital}
                        onChange={(e) => setTradingCapital(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Position Size</label>
                    <div className="col-sm-9">
                      <div className="row">
                        <div className="col-6">
                          <input
                            type="number"
                            className="form-control"
                            placeholder=""
                            value={positionSize}
                            onChange={(e) => setPositionSize(e.target.value)}
                          />
                        </div>
                        <div className="col-6">
                          <select className="form-control" onChange={(e) => changePositionSizeType(e.target.value)}>
                            <option value={'usdt'} selected={positionSizeType === 'usdt' ? true : false}>USDT</option>
                            <option value={'percent'} selected={positionSizeType === 'percent' ? true : false}>%</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Reinvest Profits</label>
                    <div className="col-sm-9">
                      <select className="form-control" onChange={(e) => setReinvestProfit(e.target.value)}>
                        <option value={1} selected={reinvestProfit === 1 ? true : false}>Yes</option>
                        <option value={0} selected={reinvestProfit === 0 ? true : false}>No</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label"></label>
                    <div className="col-sm-9">
                      <button className="btn btn-md btn-info mr-3 mb-2" disabled={disabledButton} onClick={() => editExchange()}>
                        Save
                      </button>

                      <button className="btn btn-md btn-warning text-white mr-3 mb-2" disabled={disabledButton} onClick={() => checkExchange()}>
                        Check connection
                      </button>

                      <button className="btn btn-md btn-danger mr-3 mb-2" disabled={disabledButton} onClick={() => setBasicModal(true)}>
                        Delete
                      </button>

                      
                      {successUpdate === true && (
                        <Alert variant="success" className="mt-3 alert-dismissible fade show">
                          The data has been successfully updated.
                        </Alert>
                      )}

                      {error !== "" && (
                        <Alert variant="danger" className="mt-3 alert-dismissible fade show">
                          {error}
                        </Alert>
                      )}

                    </div>
                  </div>

                  {/* <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-md btn-info">
                        Connect
                      </button>
                    </div>
                  </div> */}

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Delete Exchange</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setBasicModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">Are you sure you want to delete this exchange?</p>
          <button className="btn btn-md btn-danger btn-block mt-3" onClick={() => deleteExchange()} disabled={disabledDeleteButton}>
            {disabledDeleteButton === false && (
              <span>Delete</span>
            )}
            {disabledDeleteButton === true && (
              <span>...</span>
            )}
          </button>

          {/* <p className="text-center pt-2" style={{lineHeight: '1.1'}}>
            <small></small>
          </p> */}

        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            onClick={() => setBasicModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ExchangesEdit;
