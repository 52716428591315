import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

// image
import logo from "../../images/logo/logo_full.png";
import loadingGif from "../../images/loading.gif";

import axios from 'axios';
import { baseURL } from "../../baseURL";
import Cookies from 'js-cookie';

const Login = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  function isValidEmail(email) {
    // Regular expression for email validation
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setDisabledButton(true);

    if (!isValidEmail(email)) {
      setError("Email is not valid.")
      setDisabledButton(false);
      return "";
    }

    if(password.length === 0) {
      setError("The Password field is blank.")
      setDisabledButton(false);
      return "";
    }

    const data = {
      email: email,
      password: password
    }

    try {
      const response = await axios.post(baseURL+'/api/admin/user/login', data);
      if(response.data.status === 'ok') {
        Cookies.set('token', response.data.token, { expires: 1, path: '/'});
        Cookies.set('email', response.data.email, { expires: 1, path: '/'});
        history.push("/");
      } else {
        setError(response.data.text);
      }
    } catch(err) {

    }
    
    setDisabledButton(false);

    // history.push("/");
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} width="120" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign in your Admin account</h4>

                    {error !== "" && (
                      <Alert variant="danger" className="alert-dismissible fade show">
                        {error}
                      </Alert>
                    )}

                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="hello@example.com"
                          onChange={(e) => {setEmail(e.target.value); setError('')}}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => {setPassword(e.target.value); setError('')}}
                        />
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox ml-1 ">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="basic_checkbox_1"
                            >
                              Remember my preference
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <Link className="" to="page-forgot-password">
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btn btn-primary btn-block justify-content-center align-items-center h-100" disabled={disabledButton} >
                          {disabledButton === false && (
                            <span>Log In</span>
                          )}
                          {disabledButton === true && (
                            <span><img alt="" src={loadingGif} width="18" /></span>
                          )}
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Don't have an account?{" "}
                        <Link className="text-primary" to="./page-register">
                          Sign up
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
