import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import logo from "../../images/logo/logo_full.png";
import loadingGif from "../../images/loading.gif";
import axios from 'axios';
import { baseURL } from "../../baseURL";
import Cookies from 'js-cookie';

const Register = () => {
  const [error, setError] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successSendEmail, setSuccessSendEmail] = useState(false);
  const [confirmEmailStatus, setConfirmEmailStatus] = useState('');
  
  const submitHandler = async (e) => {
    e.preventDefault();
    setDisabledButton(true);
    setLoading(true);
    setSuccessSendEmail(false);
    setConfirmEmailStatus('');

    const data = {
      token: Cookies.get('token')
    }

    try {
      const response = await axios.post(baseURL+'/api/user/resend-confirm-email', data);
      if(response.data.status === 'ok') {
        setSuccessSendEmail(true);
      } else {
        setError(response.data.text);
      }
    } catch(err) {
    }
    
    setDisabledButton(false);
    setLoading(false);
  };

  function logOut() {
    Cookies.remove('token');
    Cookies.remove('email');
    window.location.href = '/page-login';
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const confirm_email_status = queryParameters.get("confirm-email-status")
    setConfirmEmailStatus(confirm_email_status);
  }, []);

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} width="120" alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Confirm your email</h4>

                    {confirmEmailStatus !== 'success' && (
                      <div>
                        {successSendEmail === true && (
                          <Alert variant="success" className="alert-dismissible fade show">
                            The email containing the confirmation link has been sent to your email address: <b>{Cookies.get('email')}</b>
                          </Alert>
                        )}

                        {confirmEmailStatus === 'failed' && (
                          <Alert variant="warning" className="alert-dismissible fade show">
                            The email has not been confirmed. Please try again.
                          </Alert>
                        )}

                        {error !== "" && (
                          <Alert variant="danger" className="alert-dismissible fade show">
                            {error}
                          </Alert>
                        )}

                        <p className="text-center">
                          Please confirm your email address to continue using the service. The email containing the confirmation link has been sent to your email address: <b>{Cookies.get('email')}</b>
                        </p>
                        
                        <form onSubmit={(e) => submitHandler(e)}>
                          
                          <div className="text-center mt-4">
                            <button type="submit" className="btn btn-primary btn-block justify-content-center align-items-center h-100" disabled={disabledButton}>
                              {loading === false && (
                                <span>Resend Confirmation Link</span>
                              )}
                              {loading === true && (
                                <span><img alt="" src={loadingGif} width="18" /></span>
                              )}
                            </button>
                          </div>
                        </form>

                        <p className="text-center mt-2" style={{textDecoration: 'underline'}}><small style={{cursor: 'pointer'}} onClick={() => logOut()}>Log out</small></p>
                      </div>
                    )}
                    {confirmEmailStatus === 'success' && (
                      <div>
                        <Alert variant="success" className="alert-dismissible fade show">
                          The email has been successfully confirmed.
                        </Alert>
                          
                        <div className="text-center mt-4">
                          <Link to="/" className="btn btn-primary btn-block justify-content-center align-items-center h-100">
                            <span>Open Dashboard</span>
                          </Link>
                        </div>

                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
